<!-- 排序单框拖拽 -->
<template>
  <div>
    <div class="row">
      <div class="pull-left dragBox">
        <draggable
          class="list-group ms-column itemBox"
          v-model="originFields"
          group="tasks"
          animation="300"
        >
        <!-- 默认视图-->
          <span
            class="dragItem"
            v-for="(element,index) in originFields"
            :key="index"
          >{{element.label}}<span>{{element.isdefault==1?'('+$t('label.view.sortview.default')+')':''}}</span></span>
        </draggable>
        <div class="btns">
          <!--取消  -->
          <el-button size="small" style="margin-top:10px;" @click="hideTool">{{$t('label.ems.cancel')}}</el-button>
          <!-- 保存 -->
          <el-button type="primary" size="small" @click="save">{{$t('label.save')}}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { GetSortView, SaveSortView } from "./api.js";

export default {
  name: "app",
  components: {
    draggable,
  },
  props: ["objId", "viewId"],
  data() {
    return {
      newTask: "",
      originFields: [],
    };
  },
  mounted() {
  },
  methods: {
    //获取视图排序
    getSortView() {
      const params = {
        objId: this.$route.params.objId,
      };
      GetSortView(params).then((res) => {
        this.originFields = res.data;
        this.$emit('getOriginFields',this.originFields)
      });
    },
    //取消
    hideTool() {
      let data = {
        flag:false,
        refresh:false
      }
      this.$emit("sortFun", data);
    },
    //保存
    save() {
      const sum = [];
      this.originFields.forEach((val, index) => {
        sum.push(val.id);
      });
      const params = {
        viewIds: sum.join(";"),
        objId: this.objId,
      };
      SaveSortView(params).then((res) => {
        if (res.result === true) {
          // 保存成功
          this.$message({showClose: true,
            type: "success",
            message: this.$i18n.t('label.search.saveok'),
          });
          let data = {
            flag:false,
            refresh:true
          }
          this.$emit("sortFun", data);
        } else {
          // 保存失败
          this.$message({showClose: true,
            type: "error",
            message: this.$i18n.t('savefail'),
          });
        }
      });
     
    },
  },
};
</script>

<style lang="scss" scoped>
.itemBox {
  padding: 5px 0;
  min-height: 100px;
  max-height: 135px;
  overflow: auto;
  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 15px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      background: #ddd;
      cursor: pointer;
    }
  }
}
.btns {
  margin-left: 23px;
  margin-bottom: 15px;
}
</style>