/* 
  二次删除弹框：删除报表和视图那两个接口，有图表引用的时候，传”true“才能删除
  开发人员：李仪波 
*/
<template>
  <el-dialog
    :visible.sync="secondDeleteToProp"
    :title="$t('label.delete')"
    top="15%"
    width="25%"
    :close-on-click-modal="false"
    :before-close="secondDeleteClose"
  >
    <span style="font-size: 16px; font-weight: bold">{{ markedWords }} </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="secondDeleteClose" size="mini">
        <!-- 取消 -->
        {{ $t("label.cancel") }}
      </el-button>
      <el-button @click="secondDeleteConfirm" type="primary" size="mini">
        <!-- 删除 -->
        {{ $t("label.delete") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props: {
    secondDeleteTo: {
      type: Boolean,
      default: false,
    },
    // 删除提示语
    markedWords: {
      type: String,
      default: "",
    },
  },
  computed: {
    secondDeleteToProp() {
      return this.secondDeleteTo
    }
  },

  methods: {
    // 二次删除弹框取消
    secondDeleteClose() {
      this.$emit("secondDeleteClose");
    },
    // 二次删除弹框确认
    secondDeleteConfirm() {
      this.$emit("secondDeleteConfirm");
    },
  },
};
</script>

<style lang='scss' scoped>
</style>